/* Dark theme basics */
body.dark-theme {
  background-color: #2d2d2d;
  color: #e0e0e0;
}

/* Main content area - with higher specificity */
body.dark-theme .main,
body.dark-theme div.main,
body.dark-theme [class*="makeStyles-main"] {
  background: #2d2d2d !important;
  background-image: none !important;
  color: #e0e0e0;
}

/* Grid Components */
.dark-theme .MuiGrid-root,
.dark-theme .MuiGrid-container,
.dark-theme .MuiGrid-item {
  background-color: #2d2d2d;
  color: #e0e0e0;
}

/* Typography */
.dark-theme .MuiTypography-root,
.dark-theme p,
.dark-theme span,
.dark-theme h1,
.dark-theme h2,
.dark-theme h3,
.dark-theme h4,
.dark-theme h5,
.dark-theme h6 {
  color: #e0e0e0;
}

/* DataGrid */
.dark-theme .MuiDataGrid-root {
  background-color: #2d2d2d;
  color: #e0e0e0;
}

.dark-theme .MuiDataGrid-columnHeaders {
  background-color: #2d2d2d;
  color: #e0e0e0;
}

/* DataGrid Pagination */
.dark-theme .MuiTablePagination-root,
.dark-theme .MuiTablePagination-selectLabel,
.dark-theme .MuiTablePagination-displayedRows {
  color: #e0e0e0;
}

/* Navigation and Tabs */
.dark-theme .MuiTabs-root {
  background-color: #2d2d2d;
  color: #e0e0e0;
}

.dark-theme .MuiTab-root {
  color: #e0e0e0;
}

/* Paper components (cards, dialogs, etc) */
.dark-theme .MuiPaper-root {
  background-color: #2d2d2d;
  color: #e0e0e0;
}

/* Dialog/Modal */
.dark-theme .MuiDialog-paper {
  background-color: #2d2d2d;
  color: #e0e0e0;
}

/* Inputs */
.dark-theme .MuiInputBase-root {
  color: #e0e0e0;
}

/* Select Menus */
.dark-theme .MuiSelect-root {
  color: #e0e0e0;
}

.dark-theme .MuiMenuItem-root {
  color: #e0e0e0;
}

/* Dropdowns and Select Components */
.dark-theme .MuiSelect-select,
.dark-theme .MuiSelect-selectMenu,
.dark-theme .MuiAutocomplete-input {
  color: #e0e0e0;
}

/* Dropdown Menu Items */
.dark-theme .MuiMenu-paper,
.dark-theme .MuiAutocomplete-paper {
  background-color: #2d2d2d;
  color: #e0e0e0;
}

.dark-theme .MuiMenuItem-root {
  color: #e0e0e0;
}

.dark-theme .MuiMenuItem-root:hover {
  background-color: rgba(56, 81, 112, 0.1);
}

/* Search Select Options */
.dark-theme .MuiAutocomplete-option {
  color: #e0e0e0;
}

.dark-theme .MuiAutocomplete-option[data-focus="true"] {
  background-color: rgba(56, 81, 112, 0.1);
}

/* Input Labels */
.dark-theme .MuiInputLabel-root {
  color: #e0e0e0;
}

/* Search Input */
.dark-theme .MuiInputBase-input {
  color: #e0e0e0;
}

/* DataGrid Menu Icons */
.dark-theme .MuiSelect-icon {
  color: #e0e0e0;
}

/* Autocomplete borders */
.dark-theme .MuiOutlinedInput-notchedOutline {
  border-color: #e0e0e0 !important;
}

.dark-theme .MuiAutocomplete-root .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #e0e0e0 !important;
}

.dark-theme .MuiAutocomplete-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #e0e0e0 !important;
}

/* Card Components */
.dark-theme .MuiCard-root {
    background-color: #2d2d2d;
    color: #e0e0e0;
}

.dark-theme .MuiCardHeader-root {
    background-color: #2d2d2d;
    color: #e0e0e0;
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-theme .MuiCardContent-root {
    background-color: #2d2d2d;
    color: #e0e0e0;
}

/* Table Components in Cards */
.dark-theme .MuiTableHead-root {
    background-color: #363636;
}

.dark-theme .MuiTableCell-head {
    background-color: #363636;
    color: #e0e0e0;
}

.dark-theme .MuiTableCell-body {
    color: #e0e0e0;
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-theme .MuiTableRow-root:hover {
    background-color: rgba(255, 255, 255, 0.05);
}

/* Card Title and Category */
.dark-theme .card-category {
    color: #a0a0a0;
}

.dark-theme .card-title {
    color: #e0e0e0;
}

/* Scrollable Lists in Cards */
.dark-theme .MuiCard-root .scrollable-list {
    background-color: #2d2d2d;
    border-color: rgba(255, 255, 255, 0.1);
}

/* Analytics Values */
.dark-theme .analytics-value {
    color: #e0e0e0;
}

.dark-theme .analytics-label {
    color: #a0a0a0;
}

/* Card Components - More specific selectors */
.dark-theme .MuiCard-root,
.dark-theme div[class*="makeStyles-card"],
.dark-theme div[class*="Card-root"] {
    background-color: #2d2d2d !important;
    color: #e0e0e0 !important;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
}

.dark-theme .MuiCardHeader-root,
.dark-theme div[class*="CardHeader-root"] {
    background-color: #2d2d2d !important;
    color: #e0e0e0 !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-theme .MuiCardContent-root,
.dark-theme div[class*="CardContent-root"],
.dark-theme .MuiCardBody-root,
.dark-theme div[class*="CardBody-root"] {
    background-color: #2d2d2d !important;
    color: #e0e0e0 !important;
}

/* Table Components - More specific selectors */
.dark-theme .MuiTableContainer-root {
    background-color: #2d2d2d !important;
}

.dark-theme .MuiTableHead-root,
.dark-theme thead[class*="TableHead-root"] {
    background-color: #363636 !important;
}

.dark-theme .MuiTableCell-head,
.dark-theme th[class*="TableCell-head"] {
    background-color: #363636 !important;
    color: #e0e0e0 !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.dark-theme .MuiTableBody-root,
.dark-theme tbody[class*="TableBody-root"] {
    background-color: #2d2d2d !important;
}

.dark-theme .MuiTableCell-body,
.dark-theme td[class*="TableCell-body"] {
    color: #e0e0e0 !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.dark-theme .MuiTableRow-root:hover {
    background-color: rgba(255, 255, 255, 0.05) !important;
}

/* Analytics and Stats */
.dark-theme .analytics-item,
.dark-theme div[class*="analyticsItem"] {
    background-color: #2d2d2d !important;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-theme .analytics-label,
.dark-theme div[class*="analyticsLabel"] {
    color: #a0a0a0 !important;
}

.dark-theme .analytics-value,
.dark-theme div[class*="analyticsValue"] {
    color: #e0e0e0 !important;
}

/* List Items and Sales */
.dark-theme .list-item,
.dark-theme div[class*="listItem"],
.dark-theme .sale-item,
.dark-theme div[class*="saleItem"] {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-theme .sale-details,
.dark-theme div[class*="saleDetails"] {
    color: #a0a0a0 !important;
}

/* Best Day Section */
.dark-theme .best-day-section,
.dark-theme div[class*="bestDaySection"] {
    background-color: #363636 !important;
}

.dark-theme .best-day-items,
.dark-theme div[class*="bestDayItems"] {
    background-color: #2d2d2d !important;
}

/* Scrollable Areas */
.dark-theme .scrollable-list,
.dark-theme div[class*="scrollableList"] {
    background-color: #2d2d2d !important;
    border-color: rgba(255, 255, 255, 0.1);
}

/* Icons */
.dark-theme .MuiSvgIcon-root {
    color: #ffffff !important;
}
